import { FirebaseApp, initializeApp } from "firebase/app";
import {
  Firestore,
  getFirestore,
  addDoc,
  updateDoc,
  deleteDoc,
  getDoc,
  collection,
  CollectionReference,
  doc,
  getDocs,
  DocumentReference,
  Query,
  query,
  orderBy,
  QuerySnapshot,
  DocumentSnapshot,
} from "firebase/firestore";
import {
  getAuth,
  Auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
  signOut,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import {
  FirebaseStorage,
  getStorage,
  ref,
  StorageReference,
  uploadBytes,
  deleteObject,
  getDownloadURL,
} from "firebase/storage";

import React from "react";
import { Customer, Customers } from "../store/customers/types";
import { File } from "../store/files/types";
import { Plan, Plans } from "../store/plans/types";
import { Project, Projects } from "../store/projects/types";

var firebaseConfig = {
  apiKey: "AIzaSyA2n7o81MtVqWZGCbmPDp1ZkjvFaw8oQcE",
  authDomain: "powerconcept-app-2bd19.firebaseapp.com",
  databaseURL: "https://powerconcept-app-2bd19.firebaseio.com",
  projectId: "powerconcept-app-2bd19",
  storageBucket: "powerconcept-app-2bd19.appspot.com",
  messagingSenderId: "240602286454",
  appId: "1:240602286454:web:97061de90a2f55b416d257",
};

enum docTypes {
  customer = 0,
  project = 1,
  plan = 2,
  index = 3,
}

/**
 *
 */
class Firebase {
  auth: Auth;
  db: Firestore;
  storage: FirebaseStorage;
  app: FirebaseApp;
  constructor() {
    const firebaseApp = initializeApp(firebaseConfig);
    this.app = firebaseApp;
    this.auth = getAuth(firebaseApp);
    this.db = getFirestore(firebaseApp);
    this.storage = getStorage(firebaseApp);
  }

  private getCustomerRef = (customerId: string): DocumentReference => {
    return this.getDocumentRef(docTypes.customer, customerId);
  };
  private getProjectRef = (
    customerId: string,
    projectId: string
  ): DocumentReference => {
    return this.getDocumentRef(docTypes.project, customerId, projectId);
  };
  private getPlanRef = (
    customerId: string,
    projectId: string,
    planId: string
  ): DocumentReference => {
    return this.getDocumentRef(docTypes.plan, customerId, projectId, planId);
  };
  private getIndexRef = (
    customerId: string,
    projectId: string,
    planId: string,
    indexId: string
  ): DocumentReference => {
    return this.getDocumentRef(
      docTypes.index,
      customerId,
      projectId,
      planId,
      indexId
    );
  };

  private getDocumentRef = (
    type: docTypes,
    customerId?: string,
    projectId?: string,
    planId?: string,
    indexId?: string
  ): DocumentReference => {
    const customersRef: CollectionReference = collection(this.db, "customer");
    var docRef: DocumentReference = doc(customersRef, customerId);

    if (type >= 1) {
      const projectsRef: CollectionReference = collection(docRef, "projects");
      docRef = doc(projectsRef, projectId);
    }
    if (type >= 2) {
      const plansRef: CollectionReference = collection(docRef, "plans");
      docRef = doc(plansRef, planId);
    }
    if (type >= 3) {
      const indicesRef: CollectionReference = collection(docRef, "files");
      docRef = doc(indicesRef, indexId);
    }

    return docRef;
  };

  private getCustomersRef = (): CollectionReference => {
    return this.getCollectionRef(docTypes.customer);
  };
  private getProjectsRef = (customerId: string): CollectionReference => {
    return this.getCollectionRef(docTypes.project, customerId);
  };
  private getPlansRef = (
    customerId: string,
    projectId: string
  ): CollectionReference => {
    return this.getCollectionRef(docTypes.plan, customerId, projectId);
  };
  private getIndicesRef = (
    customerId: string,
    projectId: string,
    planId: string
  ): CollectionReference => {
    return this.getCollectionRef(docTypes.index, customerId, projectId, planId);
  };

  private getCollectionRef = (
    type: docTypes,
    customerId?: string,
    projectId?: string,
    planId?: string
  ): CollectionReference => {
    var collRef: CollectionReference = collection(this.db, "customer");

    if (type >= 1) {
      const customerRef: DocumentReference = doc(collRef, customerId);
      collRef = collection(customerRef, "projects");
    }
    if (type >= 2) {
      const projectRef: DocumentReference = doc(collRef, projectId);
      collRef = collection(projectRef, "plans");
    }
    if (type >= 3) {
      const planRef: DocumentReference = doc(collRef, planId);
      collRef = collection(planRef, "files");
    }

    return collRef;
  };

  // *** FIREBASE AUTHENTIFICATION ***
  doCreateUserWithEmailAndPassword = async (email: string, password: string) =>
    await createUserWithEmailAndPassword(this.auth, email, password);

  doSignInWithEmailAndPassword = async (email: string, password: string) =>
    await signInWithEmailAndPassword(this.auth, email, password);

  doSignOut = async () => await signOut(this.auth);

  doPasswordReset = async (email: string) =>
    await sendPasswordResetEmail(this.auth, email);

  doPasswordUpdate = async (password: string) =>
    await updatePassword(this.auth.currentUser!, password);

  setPersistenceLocal = async () => {
    await setPersistence(this.auth, browserLocalPersistence);
  };

  // Add Methoden

  addCustomer = async (customer: Customer): Promise<void> => {
    const data = {
      customerId: customer.customerId,
      customerName: customer.customerName,
    };

    const customersRef = this.getCustomersRef();

    await addDoc(customersRef, data);
  };

  addProject = async (customerId: string, project: Project): Promise<void> => {
    const data = {
      projectId: project.projectId,
      projectName: project.projectName,
    };

    const projectsRef = this.getProjectsRef(customerId);

    await addDoc(projectsRef, data);
  };

  addPlan = async (
    customerId: string,
    projectId: string,
    plan: Plan
  ): Promise<void> => {
    const data = {
      planId: plan.planId,
      planName: plan.planName,
      planDescription: plan.planDescription,
      participants: plan.participants,
      createdOn: plan.createdOn,
      secure: plan.secure,
    };
    const plansRef = this.getPlansRef(customerId, projectId);

    await addDoc(plansRef, data);
  };

  addIndex = async (
    customerId: string,
    projectId: string,
    planId: string,
    file: File
  ): Promise<void> => {
    const data = {
      file: file.file,
      index: file.index,
      notes: file.notes,
    };
    const indicesRef = this.getIndicesRef(customerId, projectId, planId);

    await addDoc(indicesRef, data);
  };

  uploadFile = async (
    customerId: string,
    projectId: string,
    planId: string,
    file: any,
    fileObject: File
  ): Promise<void> => {
    const storageRef: StorageReference = ref(this.storage);
    const plansRef: StorageReference = ref(storageRef, "plans");
    const customerRef: StorageReference = ref(plansRef, customerId);
    const projectRef: StorageReference = ref(customerRef, projectId);
    const planRef: StorageReference = ref(projectRef, planId);
    const indexRef: StorageReference = ref(planRef, fileObject.file);

    await uploadBytes(indexRef, file);
  };

  // Get Methoden

  getCustomers = async (): Promise<Customers> => {
    const customersRef = this.getCustomersRef();

    const q: Query = query(customersRef, orderBy("customerId"));
    const customers: QuerySnapshot = await getDocs(q);

    const customerArray: Customers = [];
    customers.forEach((customer: any) => {
      const customerData = customer.data();
      const data = {
        id: customer.id,
        customerId: customerData.customerId,
        customerName: customerData.customerName,
      } as Customer;
      customerArray.push(data);
    });
    return customerArray;
  };

  getProjects = async (customerId: string): Promise<Projects> => {
    const projectsRef = this.getProjectsRef(customerId);
    const q: Query = query(projectsRef, orderBy("projectId"));
    const projects: QuerySnapshot = await getDocs(q);

    const projectArray: Projects = [];

    projects.forEach((project: any) => {
      const projectData = project.data();
      const data = {
        id: project.id,
        projectId: projectData.projectId,
        projectName: projectData.projectName,
      };
      projectArray.push(data);
    });
    return projectArray;
  };

  getPlans = async (customerId: string, projectId: string): Promise<Plans> => {
    const plansRef = this.getPlansRef(customerId, projectId);
    const q: Query = query(plansRef, orderBy("planId"));
    const plans: QuerySnapshot = await getDocs(q);

    const planArray: Plans = [];

    plans.forEach((plan: any) => {
      const planData = plan.data();
      const data = {
        id: plan.id,
        planId: planData.planId,
        planName: planData.planName,
        planDescription: planData.planDescription,
        files: planData.files,
        participants: planData.participants,
        notes: planData.notes,
        feedback: planData.feedback,
        secure: planData.secure,
        createdOn: planData.createdOn,
      } as Plan;
      planArray.push(data);
    });
    return planArray;
  };

  getIndex = async (
    customerId: string,
    projectId: string,
    planId: string,
    indexLetter: string
  ): Promise<File> => {
    var tempIndex: File = { id: "", index: "", file: "", notes: [] };

    const filesRef = this.getIndicesRef(customerId, projectId, planId);

    const q: Query = query(filesRef);
    const files: QuerySnapshot = await getDocs(q);

    files.forEach((index: any) => {
      const indexData = index.data();
      if (indexData.index === indexLetter) {
        tempIndex = {
          id: index.id,
          index: indexData.index,
          file: indexData.file,
          notes: indexData.notes,
        };
      }
    });
    return tempIndex;
  };

  getFiles = async (
    customerId: string,
    projectId: string,
    planId: string
  ): Promise<File[]> => {
    const files: File[] = [];
    const indicesRef = this.getIndicesRef(customerId, projectId, planId);
    const q: Query = query(indicesRef);
    const tempFiles: QuerySnapshot = await getDocs(q);

    tempFiles.forEach((file: any) => {
      const fileData = file.data();
      const data = {
        id: file.id,
        index: fileData.index,
        file: fileData.file,
        notes: fileData.notes,
      } as File;
      files.push(data);
    });

    return files as File[];
  };

  getUserById = async (userId: string): Promise<DocumentSnapshot> => {
    const usersRef: CollectionReference = collection(this.db, "users");
    const userRef: DocumentReference = doc(usersRef, userId);

    const user = await getDoc(userRef);

    return user;
  };

  getUsers = async (): Promise<QuerySnapshot> => {
    const usersRef: CollectionReference = collection(this.db, "users");
    const q: Query = query(usersRef);

    const users: QuerySnapshot = await getDocs(q);

    return users;
  };

  getDownloadUrl = async (link: string): Promise<string> => {
    const tempRef = ref(this.storage, link);
    const url = getDownloadURL(tempRef);

    return url;
  };

  // Update Methoden

  updateCustomer = async (customer: Customer): Promise<void> => {
    const data = {
      customerId: customer.customerId,
      customerName: customer.customerName,
    };
    const customerRef = this.getCustomerRef(customer.id);

    await updateDoc(customerRef, data);
  };

  updateProject = async (
    customerId: string,
    project: Project
  ): Promise<void> => {
    const data = {
      projectId: project.projectId,
      projectName: project.projectName,
    };
    const projectRef = this.getProjectRef(customerId, project.id);

    await updateDoc(projectRef, data);
  };

  updatePlan = async (
    customerId: string,
    projectId: string,
    plan: Plan
  ): Promise<void> => {
    const data = {
      planId: plan.planId,
      planName: plan.planName,
      planDescription: plan.planDescription,
    };
    const planRef = this.getPlanRef(customerId, projectId, plan.id);

    await updateDoc(planRef, data);
  };

  // Delete Methoden

  deleteCustomer = async (customerId: string): Promise<void> => {
    //Lädt die zum Kunden gehörigen Projekte
    const projects: Projects = await this.getProjects(customerId);

    projects.forEach(async (project: Project) => {
      //Löscht das Projekt (hierbei wird der Plan inklusive Indizes mitgelöscht)
      await this.deleteProject(customerId, project.id);
    });

    //Löscht den Kunden
    const customerRef = this.getCustomerRef(customerId);

    await deleteDoc(customerRef);
  };

  deleteProject = async (
    customerId: string,
    projectId: string
  ): Promise<void> => {
    //Lädt die zum Projekt gehörigen Pläne
    const plans: Plans = await this.getPlans(customerId, projectId);

    plans.forEach(async (plan: Plan) => {
      //Löscht den Plan (inklusive Indizes)
      await this.deletePlan(customerId, projectId, plan.id);
    });

    //Löscht das Projekt
    const projectRef = this.getProjectRef(customerId, projectId);

    await deleteDoc(projectRef);
  };

  deletePlan = async (
    customerId: string,
    projectId: string,
    planId: string
  ): Promise<void> => {
    //Lädt alle zum Plan gehörigen Indizes
    const files = await this.getFiles(customerId, projectId, planId);

    files.forEach(async (file: File) => {
      //Löscht die pdf
      await this.deleteFile(customerId, projectId, planId, file.file);
      //Löscht den Index
      await this.deleteIndex(customerId, projectId, planId, file.id);
    });

    //Löscht den Plan
    const planRef = this.getPlanRef(customerId, projectId, planId);

    await deleteDoc(planRef);
  };

  deleteIndex = async (
    customerId: string,
    projectId: string,
    planId: string,
    indexId: string
  ): Promise<void> => {
    const indexRef = this.getIndexRef(customerId, projectId, planId, indexId);

    await deleteDoc(indexRef);
  };

  deleteFile = async (
    customerId: string,
    projectId: string,
    planId: string,
    fileName: string
  ): Promise<void> => {
    const storageRef: StorageReference = ref(this.storage);
    const plansRef: StorageReference = ref(storageRef, "plans");
    const customerRef: StorageReference = ref(plansRef, customerId);
    const projectRef: StorageReference = ref(customerRef, projectId);
    const planRef: StorageReference = ref(projectRef, planId);
    const fileRef: StorageReference = ref(planRef, fileName);

    await deleteObject(fileRef);
  };
}

export const FirebaseContext = React.createContext<Firebase | null>(null);

export default Firebase;
