import React from "react";
import Footer from "../components/Footer";
import PlanNavigationBar from "./PlanNavigationBar";

type PlanLayoutProps = {};

const PlanLayout: React.FC<PlanLayoutProps> = ({ children }) => {
  return (
    <>
      <PlanNavigationBar />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default PlanLayout;
