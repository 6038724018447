import React, { useState } from "react";
import { Customer } from "../../store/customers/types";
import { Plan } from "../../store/plans/types";
import { Project } from "../../store/projects/types";
import DeleteEntityModal from "../modals/DeleteEntityModal";
import EditPlanModal from "../modals/plan/EditPlanModal";
import QrModal from "../modals/QrModal";
import InformationCard from "./InformationCard";

type PlanCardProps = {
  plan: Plan;
  selectedCustomer: Customer;
  selectedProject: Project;
  getPlans: (customer: Customer, project: Project) => void;
};

const PlanCard: React.FC<PlanCardProps> = ({
  plan,
  selectedCustomer,
  selectedProject,
  getPlans,
}) => {
  const [visible, setVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  return (
    <div id={plan.id}>
      <EditPlanModal
        customer={selectedCustomer}
        project={selectedProject}
        plan={plan}
        visible={editModalVisible}
        setVisible={setEditModalVisible}
        refresh={getPlans}
      />
      <DeleteEntityModal
        entity="plan"
        customer={selectedCustomer}
        project={selectedProject}
        plan={plan}
        refresh={() => {
          getPlans(selectedCustomer, selectedProject);
        }}
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
      />
      <QrModal
        visible={visible}
        setVisible={setVisible}
        customerId={selectedCustomer.id}
        projectId={selectedProject.id}
        plan={plan}
      />
      <InformationCard
        cardOnClick={() => {
          setVisible(true);
        }}
        editOnClick={() => setEditModalVisible(true)}
        deleteOnClick={() => {
          setDeleteModalVisible(true);
        }}
        id={plan.planId}
        name={plan.planName}
      />
    </div>
  );
};

export default PlanCard;
