import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import logo from "../logosmall.png";

type PlanNavigationBarProps = {};

const PlanNavigationBar: React.FC<PlanNavigationBarProps> = () => {
  return (
    <div className="plansreen-nav-color">
      <div className="wrapper">
        <Navbar expand="lg">
          <Navbar.Brand href="https://www.power-concept.com">
            <img
              height={30}
              alt="Powerconcept Monshausen"
              src={logo}
              style={{ paddingRight: 25 }}
            />
            {/* <img className="nav-brand-image" src={data.logo.publicURL} /> */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto d-flex align-items-center">
              <a
                className="text-white mr-3 my-2"
                href="https://www.power-concept.com/"
              >
                HOME
              </a>
              <a
                className="text-white mr-3 my-2"
                href="https://www.power-concept.com/beratung"
              >
                BERATUNG
              </a>
              <a
                className="text-white mr-3 my-2"
                href="https://www.power-concept.com/planung"
              >
                PLANUNG
              </a>
              <a
                className="text-white mr-3 my-2"
                href="https://www.power-concept.com/betreuung"
              >
                BETREUUNG
              </a>
              <a
                className="text-white mr-3 my-2"
                href="https://www.power-concept.com/kontakt"
              >
                KONTAKT
              </a>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
    // <>
    //   <Navbar
    //     className="navigation-bar"
    //     collapseOnSelect
    //     expand="lg"
    //     bg="white"
    //     variant="light"
    //   >
    //     <Navbar.Brand className="d-flex align-items-center">
    //       <img
    //         height={30}
    //         alt="Powerconcept Monshausen"
    //         src={logo}
    //         style={{ paddingRight: 25 }}
    //       />
    //       Powerconcept
    //     </Navbar.Brand>
    //   </Navbar>
    // </>
  );
};

export default PlanNavigationBar;
