import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Customer, Customers } from "../store/customers/types";
import { Plans } from "../store/plans/types";
import { Project, Projects } from "../store/projects/types";
import AddCustomerModal from "./modals/customer/AddCustomerModal";
import AddPlanModal from "./modals/plan/AddPlanModal";
import AddProjectModal from "./modals/project/AddProjectModal";

type InputFormProps = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  customers: Customers;
  selectedCustomer: Customer | null;
  setSelectedCustomer: Dispatch<SetStateAction<Customer | null>>;
  getCustomers: () => void;
  projects: Projects | null;
  selectedProject: Project | null;
  setSelectedProject: Dispatch<SetStateAction<Project | null>>;
  getProjects: (customer: Customer) => void;
  getPlans: (customer: Customer, project: Project) => void;
  plans: Plans | null;
};

const InputForm: React.FC<InputFormProps> = ({
  isLoading,
  setIsLoading,
  customers,
  selectedCustomer,
  setSelectedCustomer,
  getCustomers,
  getProjects,
  projects,
  selectedProject,
  setSelectedProject,
  getPlans,
  plans,
}) => {
  const [customerModalVisible, setCustomerModalVisible] = useState(false);
  const [projectModalVisible, setProjectModalVisible] = useState(false);
  const [planModalVisible, setPlanModalVisible] = useState(false);

  useEffect(() => {
    console.log("Loading customers...");
  }, []);

  return (
    <>
      <AddCustomerModal
        visible={customerModalVisible}
        setVisible={setCustomerModalVisible}
        refreshCustomers={getCustomers}
      />
      <AddProjectModal
        customers={customers}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        getProjects={getProjects}
        setSelectedProject={setSelectedProject}
        visible={projectModalVisible}
        setVisible={setProjectModalVisible}
      />
      <AddPlanModal
        customers={customers}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        getProjects={getProjects}
        projects={projects}
        selectedProject={selectedProject}
        setSelectedProject={setSelectedProject}
        getPlans={getPlans}
        plans={plans}
        visible={planModalVisible}
        setVisible={setPlanModalVisible}
      />
      <div className="py-2">
        <div className="d-flex justify-content-between">
          <h2 className="text-dark">Verwaltung</h2>
        </div>
      </div>
      <Form className="">
        <Form.Group className="d-flex flex-column">
          <Button
            className="d-flex align-items-center justify-content-between mb-4 overview-card"
            variant="light"
            style={{
              width: "180px",
              borderWidth: 1,
              borderColor: "lightgrey",
              borderStyle: "solid",
            }}
            onClick={() => setCustomerModalVisible(true)}
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-plus-square"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
              />
              <path
                fillRule="evenodd"
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
              />
            </svg>
            <div className="pl-2">Kunde anlegen</div>
          </Button>
          <Button
            className="d-flex align-items-center justify-content-between mb-4 overview-card"
            style={{
              width: "180px",
              borderWidth: 1,
              borderColor: "lightgrey",
              borderStyle: "solid",
            }}
            variant="light"
            onClick={() => setProjectModalVisible(true)}
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-plus-square"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
              />
              <path
                fillRule="evenodd"
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
              />
            </svg>
            <div className="pl-2">Projekt anlegen</div>
          </Button>
          <Button
            className="d-flex align-items-center justify-content-between overview-card"
            style={{
              width: "180px",
              borderWidth: 1,
              borderColor: "lightgrey",
              borderStyle: "solid",
            }}
            variant="light"
            onClick={() => setPlanModalVisible(true)}
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-plus-square"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
              />
              <path
                fillRule="evenodd"
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
              />
            </svg>
            <div className="pl-2">Plan anlegen</div>
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

export default InputForm;
