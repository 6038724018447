import React, { useContext, useState } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FirebaseContext } from "../firebase/Firebase";
import logo from "../logosmall.png";
import { RootState } from "../store/rootReducer";
import ChangePasswordModal from "./modals/user/ChangePasswordModal";
import UserManagementModal from "./modals/user/UserManagementModal";

type NavigationBarProps = {
  title: string;
};

const NavigationBar: React.FC<NavigationBarProps> = ({ title }) => {
  const [accountSettingsVisible, setAccountSettingsVisible] = useState(false);
  const [userManagementVisible, setUserManagementVisible] = useState(false);
  const firebase = useContext(FirebaseContext);

  const selectUserData = (state: RootState) => state.userData;
  const userData = useSelector(selectUserData);

  return (
    <>
      <div className="shadow">
        <div className="wrapper">
          <ChangePasswordModal
            visible={accountSettingsVisible}
            setVisible={setAccountSettingsVisible}
          />
          {userData.role === "admin" ? (
            <>
              <UserManagementModal
                visible={userManagementVisible}
                setVisible={setUserManagementVisible}
              />
            </>
          ) : null}
          <Navbar
            className="navigation-bar py-3"
            collapseOnSelect
            expand="lg"
            bg="white"
            variant="light"
          >
            <Navbar.Brand className="d-flex align-items-center">
              <Link to="/home">
                <img
                  height={30}
                  alt="Powerconcept Monshausen"
                  src={logo}
                  style={{ paddingRight: 25 }}
                />
              </Link>
              {title}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-auto">
                <NavDropdown alignRight title="Account" id="basic-nav-dropdown">
                  <NavDropdown.Item
                    onClick={() => setAccountSettingsVisible(true)}
                  >
                    Passwort ändern
                  </NavDropdown.Item>
                </NavDropdown>
                {userData.role === "admin" ? (
                  <NavDropdown alignRight title="Admin" id="basic-nav-dropdown">
                    <NavDropdown.Item
                      onClick={() => setUserManagementVisible(true)}
                    >
                      Benutzerverwaltung
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : null}
                <Nav.Link
                  className="logout-button"
                  onClick={() => firebase!.doSignOut()}
                >
                  Logout
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </>
  );
};

export default NavigationBar;
