import React, { Dispatch, SetStateAction, useContext } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FirebaseContext } from "../../firebase/Firebase";
import { Customer } from "../../store/customers/types";
import { Plan } from "../../store/plans/types";
import { Project } from "../../store/projects/types";

type Entity = "customer" | "project" | "plan";

type DeleteEntityModalProps = {
  entity: Entity;
  customer: Customer;
  project?: Project;
  plan?: Plan;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  refresh: () => void;
};

const DeleteEntityModal: React.FC<DeleteEntityModalProps> = ({
  entity,
  customer,
  project,
  plan,
  visible,
  setVisible,
  refresh,
}) => {
  const firebase = useContext(FirebaseContext);

  const submit = async () => {
    try {
      switch (entity) {
        case "customer":
          await firebase!.deleteCustomer(customer.id);
          refresh();
          break;
        case "project":
          await firebase!.deleteProject(customer.id, project!.id);
          refresh();
          break;
        case "plan":
          await firebase!.deletePlan(customer.id, project!.id, plan!.id);
          refresh();
          break;
        default:
          console.log("test");
      }
      refresh();
      // setVisible(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {entity === "customer"
              ? //@ts-ignore
                customer.customerName
              : entity === "project"
              ? //@ts-ignore
                project.projectName
              : entity === "plan"
              ? //@ts-ignore
                plan.planName
              : " "}{" "}
            löschen?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="d-flex justify-content-between align-items-center">
              <Form.Label>Sind sie sicher?</Form.Label>
              <Button variant="danger" onClick={() => submit()}>
                Ja, löschen.
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setVisible(false)}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteEntityModal;
