import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { FirebaseContext } from "../../../firebase/Firebase";
import { Customer } from "../../../store/customers/types";
import { Project } from "../../../store/projects/types";
import toast from "react-hot-toast";

type EditProjectModalProps = {
  customer: Customer;
  project: Project;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  refresh: (customer: Customer) => void;
};

const EditProjectModal: React.FC<EditProjectModalProps> = ({
  customer,
  project,
  visible,
  setVisible,
  refresh,
}) => {
  const [tempProject, setTempProject] = useState<Project>(project);
  const [uploading, setUploading] = useState<boolean>(false);
  const firebase = useContext(FirebaseContext);

  const submit = async () => {
    try {
      //Aktualisieren des Plans in der Datenbank
      await firebase!.updateProject(customer.id, tempProject);
      refresh(customer);
      setVisible(false);
      toast.success("Das Projekt wurde erfolgreich aktualisiert.");
    } catch (error) {
      console.log(error);
      toast.error("Es ist ein Fehler aufgetreten. Bitte versuche es erneut.");
    }
  };

  useEffect(() => {
    setTempProject(project);
  }, [visible, project]);

  return (
    <>
      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{project.projectName} bearbeiten</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={async (event) => {
              //Ruft die submit() function auf
              event.preventDefault();

              setUploading(true);
              await submit().then(() => {
                setUploading(false);
                setVisible(false);
              });
            }}
          >
            <Form.Group>
              <Form.Label>Kunde: {customer.customerName}</Form.Label>
            </Form.Group>
            <Form.Group>
              <Form.Label>Projektnummer</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Projektnummer"
                value={tempProject.projectId}
                onChange={(e) =>
                  setTempProject({ ...tempProject, projectId: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Projektname</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Projektname"
                value={tempProject.projectName}
                onChange={(e) =>
                  setTempProject({
                    ...tempProject,
                    projectName: e.target.value,
                  })
                }
              />
            </Form.Group>
            {uploading ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <div className="d-flex justify-content-end">
                <Button
                  variant="danger"
                  type="submit"
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                >
                  Aktualisieren
                </Button>
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setVisible(false)}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditProjectModal;
