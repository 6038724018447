import { combineReducers } from "redux";
import { customerReducer } from "./customers/reducers";
import { Customers } from "./customers/types";
import { userDataReducer } from "./user/reducers";
import { UserData } from "./user/types";

type AppState = { userData: UserData; customers: Customers };

const rootReducer = combineReducers<AppState>({
  userData: userDataReducer,
  customers: customerReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
