import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DocumentOverview from "../components/DocumentOverview";
import InputForm from "../components/InputForm";
import Layout from "../components/Layout";
import { FirebaseContext } from "../firebase/Firebase";
import { createCustomers } from "../store/customers/actions";
import { Customer } from "../store/customers/types";
import { Plans } from "../store/plans/types";
import { Project, Projects } from "../store/projects/types";
import { RootState } from "../store/rootReducer";

type HomeScreenProps = {};

const HomeScreen: React.FC<HomeScreenProps> = () => {
  const selectCustomers = (state: RootState) => state.customers;
  // const selectUser = (state: RootState) => state.userData;

  const customers = useSelector(selectCustomers);
  // const user = useSelector(selectUser);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );
  const [projects, setProjects] = useState<Projects | null>([]);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [plans, setPlans] = useState<Plans>([]);

  const firebase = useContext(FirebaseContext);

  const getCustomers = async () => {
    try {
      setIsLoading(true);
      const customers = await firebase!.getCustomers();
      dispatch(createCustomers(customers));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getProjects = async (customer: Customer) => {
    try {
      setIsLoading(true);
      const projects = await firebase!.getProjects(customer.id);
      setProjects(projects);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getPlans = async (customer: Customer, project: Project) => {
    try {
      setIsLoading(true);
      const plans = await firebase!.getPlans(customer.id, project.id);
      setPlans(plans);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getCustomersOnLoad = async () => {
      try {
        setIsLoading(true);
        const customers = await firebase!.getCustomers();
        dispatch(createCustomers(customers));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getCustomersOnLoad();
  }, [dispatch, firebase]);

  return (
    <>
      <Layout>
        <div className="pb-4 pt-5 row">
          <div className="col-lg-2 border-right">
            <InputForm
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              customers={customers}
              selectedCustomer={selectedCustomer}
              setSelectedCustomer={setSelectedCustomer}
              getProjects={getProjects}
              getCustomers={getCustomers}
              projects={projects}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              getPlans={getPlans}
              plans={plans}
            />
          </div>
          <div className="col-lg-10">
            <DocumentOverview
              isLoading={isLoading}
              getCustomers={getCustomers}
              customers={customers}
              selectedCustomer={selectedCustomer}
              setSelectedCustomer={setSelectedCustomer}
              getProjects={getProjects}
              projects={projects}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              plans={plans}
              getPlans={getPlans}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default HomeScreen;
