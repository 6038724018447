export interface Customer {
  id: string; // fb id
  customerId: string;
  customerName: string;
}

export type Customers = Customer[];

export const CREATE_CUSTOMERS = "CREATE_CUSTOMERS";
export const UPDATE_CUSTOMERS = "UPDATE_CUSTOMERS";
export const DELETE_CUSTOMERS = "DELETE_CUSTOMERS";

interface CreateCustomersAction {
  type: typeof CREATE_CUSTOMERS;
  payload: Customers;
}

interface UpdateCustomersAction {
  type: typeof UPDATE_CUSTOMERS;
}

interface DeleteCustomersAction {
  type: typeof DELETE_CUSTOMERS;
}

export type CustomerActionTypes =
  | CreateCustomersAction
  | UpdateCustomersAction
  | DeleteCustomersAction;
