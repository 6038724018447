import axios from "axios";
import queryString from "query-string";
import React, { FormEvent, useContext, useEffect, useState } from "react";
import { Button, Dropdown, Form, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { FirebaseContext } from "../firebase/Firebase";
import DropdownItem from "../planScreenComponents/DropdownItem";
import PlanLayout from "../planScreenComponents/PlanLayout";
import StarRating from "../planScreenComponents/StarRating";
import { ReqFile, ReqFiles, ReqPlan } from "../store/plans/types";

type PlanScreenProps = {};

export type Query = {
  customer?: string;
  project?: string;
  plan?: string;
};

export const PlanScreen: React.FC<PlanScreenProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const location = useLocation();
  const [plan, setPlan] = useState<ReqPlan>();
  const [planFiles, setPlanFiles] = useState<ReqFiles>([]);
  const [selectedIndex, setSelectedIndex] = useState<ReqFile | null>(null);
  const [noteTextField, setNoteTextField] = useState<string>("");
  const [rating, setRating] = React.useState<number>(0);
  const [hoverRating, setHoverRating] = React.useState<number>(0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [query, setQuery] = useState<Query>(
    queryString.parse(location.search) as Query
  );

  const onMouseEnter = (index: number) => {
    setHoverRating(index);
  };
  const onMouseLeave = () => {
    setHoverRating(0);
  };
  const onSaveRating = (index: number) => {
    setRating(index);
  };

  const firebase = useContext(FirebaseContext);

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:5001/powerconcept-app-2bd19/europe-west1"
      : "https://europe-west1-powerconcept-app-2bd19.cloudfunctions.net";

  const addFeedbackToPlanByIds = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await axios.post(`${baseUrl}/plans/addFeedbackToPlanByIds/`, {
        customerId: query.customer,
        projectId: query.project,
        planId: query.plan,
        fileId: selectedIndex!.id,
        file: selectedIndex,
        stars: rating.toString(),
        note: noteTextField,
      });
      setNoteTextField("");
      setRating(0);
      toast.success("Bewertung abgeschickt.");
    } catch (error) {
      console.log(error);
      toast.error("Fehler.");
    }
  };

  const [url, setUrl] = useState<string>();

  useEffect(() => {
    const getPlanUrl = async (file: string) => {
      // const query = queryString.parse(location.search);
      const url = await firebase!.getDownloadUrl(
        `plans/${query!.customer}/${query!.project}/${query!.plan}/${file}`
      );

      return url;
    };

    const getPlanByIds = async () => {
      try {
        const reqPlan = await axios.get(`${baseUrl}/plans/getPlanByIds/`, {
          params: {
            customerId: query.customer,
            projectId: query.project,
            planId: query.plan,
          },
        });

        const result = reqPlan.data as ReqPlan;
        setPlan(result);
        setPlanFiles(result.files);

        const recentFile = result.files.slice();
        const latestIndex = recentFile
          .sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0))
          .pop();
        setSelectedIndex(latestIndex!);
        setUrl(await getPlanUrl(latestIndex!.file));
        setIsLoading(false);
        toast.success("PDF erfolgreich geladen.");
      } catch (error) {
        setIsLoading(false);
        setError(true);
        toast.error("Fehler beim Laden der PDF.");
      }
    };

    getPlanByIds();
  }, [firebase, baseUrl, query]);

  if (isLoading) {
    return (
      <PlanLayout>
        <div
          className="hero h-100"
          style={{
            backgroundImage: "url(/index-hero-bg.jpg)",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="d-flex justify-content-center align-items-center h-100">
              <img className="hero-img" src="/hero-logo.png" alt="" />
            </div>
          </div>
        </div>
        <div className="container my-5 d-flex flex-column align-items-center">
          <Spinner className="mb-3" animation="grow" variant="danger" />
          <h6>PDF wird geladen...</h6>
        </div>
      </PlanLayout>
    );
  }

  return (
    <>
      {!error && plan ? (
        <>
          <PlanLayout>
            <>
              <div
                className="hero"
                style={{
                  backgroundImage: "url(/index-hero-bg.jpg)",
                  backgroundSize: "cover",
                }}
              >
                <div className="container">
                  <div className="hero d-flex justify-content-center align-items-center">
                    <img className="hero-img" src="/hero-logo.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="wrapper section-holder d-flex justify-content-center">
                <div className="row w-100">
                  <div className="col-lg-6">
                    <div>
                      <h4>
                        {plan!.planId + " - " + plan!.planName} (Index{" "}
                        {selectedIndex?.index})
                      </h4>
                      {plan!.planDescription === "" ? null : (
                        <>
                          <h5>Beschreibung</h5>
                          <p>{plan!.planDescription}</p>
                        </>
                      )}
                      {plan!.participants.length > 0 ? (
                        <>
                          <h5>Ersteller</h5>
                          {plan!.participants!.map((participant) => (
                            <div key={participant.email}>
                              <p>{participant.name}</p>
                            </div>
                          ))}
                        </>
                      ) : null}
                    </div>
                    <a
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        marginBottom: "10px",
                        height: "40px",
                        borderRadius: "10px",
                      }}
                      className="plan-screen-plan-buttons d-flex justify-content-center align-items-center"
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-file-earmark-ruled"
                          viewBox="0 0 16 16"
                        >
                          <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h7v1a1 1 0 0 1-1 1H6zm7-3H6v-2h7v2z" />
                        </svg>
                        <div className="px-1"></div>
                        Aktuelle PDF öffnen
                      </div>
                    </a>
                    <Dropdown>
                      <Dropdown.Toggle
                        className="plan-screen-plan-buttons"
                        variant="light"
                      >
                        Alle Dokumente
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {/* ÄNDERUNGEN ÜBERPRÜFEN!! */}
                        {planFiles
                          .sort((a, b) => {
                            return a.index > b.index ? 1 : -1;
                          })
                          .map((file) => (
                            <div key={file.index}>
                              <DropdownItem file={file} query={query} />
                            </div>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="col-lg-6 rating-col">
                    {selectedIndex ? (
                      <>
                        <h5>Bewertung</h5>
                        <div className="d-flex">
                          {[1, 2, 3, 4, 5].map((index) => {
                            return (
                              <StarRating
                                key={index}
                                index={index}
                                rating={rating}
                                hoverRating={hoverRating}
                                onMouseEnter={onMouseEnter}
                                onMouseLeave={onMouseLeave}
                                onSaveRating={onSaveRating}
                              />
                            );
                          })}
                        </div>
                        <hr />
                        <h5>Anmerkungen</h5>
                        <Form onSubmit={(e) => addFeedbackToPlanByIds(e)}>
                          <Form.Group className="d-flex">
                            <Form.Control
                              as="textarea"
                              placeholder="Notiz eingeben..."
                              className="mr-2"
                              rows={4}
                              value={noteTextField}
                              onChange={(e) => setNoteTextField(e.target.value)}
                            />
                            <div className="d-flex align-items-end">
                              <Button variant="danger" type="submit">
                                Senden
                              </Button>
                            </div>
                          </Form.Group>
                        </Form>
                      </>
                    ) : null}
                    <div className="pt-2">
                      <div className="d-flex flex-column"></div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </PlanLayout>
        </>
      ) : (
        <PlanLayout>
          <div
            className="hero h-100"
            style={{
              backgroundImage: "url(/index-hero-bg.jpg)",
              backgroundSize: "cover",
            }}
          >
            <div className="container">
              <div className="d-flex justify-content-center align-items-center h-100">
                <img className="hero-img" src="/hero-logo.png" alt="" />
              </div>
            </div>
          </div>
          <div className="my-5">
            <div className="container text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-exclamation-triangle"
                viewBox="0 0 16 16"
              >
                <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
              </svg>
              <h6 className="pt-3">Beim Laden ist ein Fehler aufgetreten. </h6>
              {plan?.files && plan?.files.length ? null : (
                <h6>(Keine Dokumente gefunden)</h6>
              )}
            </div>
          </div>
        </PlanLayout>
      )}
    </>
  );
};
