import { Timestamp as FirebaseTimestamp } from "firebase/firestore";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Select from "react-select";
import { FirebaseContext } from "../../../firebase/Firebase";
import { Customer, Customers } from "../../../store/customers/types";
import { File } from "../../../store/files/types";
import { Plan, Plans } from "../../../store/plans/types";
import { Project, Projects } from "../../../store/projects/types";
import { RootState } from "../../../store/rootReducer";

type AddPlanModalProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  customers: Customers;
  selectedCustomer: Customer | null;
  setSelectedCustomer: Dispatch<SetStateAction<Customer | null>>;
  getProjects: (customer: Customer) => void;
  projects: Projects | null;
  selectedProject: Project | null;
  setSelectedProject: Dispatch<SetStateAction<Project | null>>;
  getPlans: (customer: Customer, project: Project) => void;
  plans: Plans | null;
};

const AddPlanModal: React.FC<AddPlanModalProps> = ({
  visible,
  setVisible,
  customers,
  selectedCustomer,
  setSelectedCustomer,
  getProjects,
  projects,
  selectedProject,
  setSelectedProject,
  getPlans,
  plans,
}) => {
  const firebase = useContext(FirebaseContext);
  const selectUserData = (state: RootState) => state.userData;
  const userData = useSelector(selectUserData);

  const [selectedPlan, setSelectedPlan] = useState<Plan>();

  const [uploading, setUploading] = useState<boolean>(false);

  // form input
  //Plan
  const [planId, setPlanId] = useState<string>("");
  const [planName, setPlanName] = useState<string>("");
  const [planDescription, setPlanDescription] = useState<string>("");
  const [isNewPlan, setIsNewPlan] = useState<boolean>(true);
  const [missingSelection, setMissingSelection] = useState<boolean>(false);
  const [missingPlan, setMissingPlan] = useState<boolean>(false);
  const [indices, setIndices] = useState<{ label: string }[]>([
    { label: "A" },
    { label: "B" },
    { label: "C" },
    { label: "D" },
    { label: "E" },
    { label: "F" },
    { label: "G" },
    { label: "H" },
    { label: "I" },
    { label: "J" },
  ]);
  //File
  const [index, setIndex] = useState<string>();
  const [file, setFile] = useState(null);

  const clearStates = () => {
    //Clearing the states
    setPlanId("");
    setPlanName("");
    setPlanDescription("");
    setIsNewPlan(true);
    setIndex("");
    setFile(null);
    setMissingSelection(false);
    setMissingPlan(false);
    setIndices([
      { label: "A" },
      { label: "B" },
      { label: "C" },
      { label: "D" },
      { label: "E" },
      { label: "F" },
      { label: "G" },
      { label: "H" },
      { label: "I" },
      { label: "J" },
    ]);
    setSelectedCustomer(null);
    setSelectedProject(null);
  };

  // useEffect(() => {
  //   const defaultStates = () => {
  //     clearStates()
  //   }

  //   defaultStates();
  // }, [visible]);

  //Neuen Plan erzeugen und hochladen (Entity)
  const createNewPlan = async () => {
    try {
      let newPlan: Plan = {
        id: "",
        participants: [
          { name: userData.name, email: firebase!.auth.currentUser!.email! },
        ],
        planId: planId!,
        planName: planName!,
        planDescription: planDescription!,
        secure: false,
        createdOn: FirebaseTimestamp.now(),
      };

      console.log(newPlan);

      await firebase!.addPlan(
        selectedCustomer!.id,
        selectedProject!.id,
        newPlan
      );
      return newPlan;
    } catch (error) {
      toast.error("Es ist ein Fehler aufgetreten. Bitte versuche es erneut.");
    }
  };

  //Neuen Index (Entitiy) anlegen
  const addFile = async (planId: string) => {
    try {
      const newFile: File = {
        id: "",
        index: index!,
        file: index! + ".pdf",
        notes: [],
      };
      await firebase!.addIndex(
        selectedCustomer!.id,
        selectedProject!.id,
        planId,
        newFile!
      );
    } catch (error) {
      toast.error("Es ist ein Fehler aufgetreten. Bitte versuche es erneut.");
    }
  };

  const submit = async () => {
    try {
      //Wenn ein neuer Plan angelegt wird, wird nur die Entity angelegt
      if (isNewPlan) {
        await createNewPlan();
      }
      //Wird ein neuer Index angelegt, wird auch das dazugehörige File (die pdf) hochgeladen
      else {
        addFile(selectedPlan!.id).then(async () => {
          await firebase!.uploadFile(
            selectedCustomer!.id,
            selectedProject!.id,
            selectedPlan!.id,
            file,
            {
              id: "",
              index: index!,
              file: index! + ".pdf",
              notes: [],
            } as File
          );
        });
      }
      clearStates();
    } catch (error) {
      toast.error("Es ist ein Fehler aufgetreten. Bitte versuche es erneut.");
    }
  };

  //Überprüfung, welche Indizes bereits existieren und diese ausblenden
  const checkIndices = async (plan: Plan) => {
    try {
      let tempIndices = [
        { label: "A" },
        { label: "B" },
        { label: "C" },
        { label: "D" },
        { label: "E" },
        { label: "F" },
        { label: "G" },
        { label: "H" },
        { label: "I" },
        { label: "J" },
      ];
      const files = await firebase!.getFiles(
        selectedCustomer!.id,
        selectedProject!.id,
        plan.id
      );
      files!.forEach((file: File) => {
        tempIndices.splice(
          tempIndices.findIndex((index) => index.label === file.index),
          1
        );
      });
      console.log(tempIndices);
      setIndices(tempIndices);
    } catch (error) {
      toast.error(
        "Index: Es ist ein Fehler aufgetreten. Bitte versuche es erneut."
      );
    }
  };

  return (
    <>
      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Plan anlegen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={async (event) => {
              //Form Validierung und anschließend submit() function aufrufen
              event.preventDefault();
              if (
                selectedCustomer === null ||
                selectedProject === null ||
                (index === undefined && !isNewPlan)
              ) {
                setMissingSelection(true);
              } else if (!isNewPlan && selectedPlan === null) {
                setMissingPlan(true);
              } else {
                setUploading(true);
                await submit().then(() => {
                  getPlans(selectedCustomer, selectedProject);
                  setUploading(false);
                  setVisible(false);
                });
              }
            }}
          >
            <Form.Group>
              <Form.Label>Kunde</Form.Label>
              <Select
                placeholder="Auswählen..."
                options={customers}
                getOptionLabel={(option) => option.customerName}
                getOptionValue={(option) => JSON.stringify(option)}
                onChange={async (customer) => {
                  setMissingSelection(false);
                  setSelectedCustomer(customer);
                  getProjects(customer!);
                  try {
                    getPlans(customer!, selectedProject!);
                  } catch (e) {
                    console.log(e);
                  }
                  setSelectedProject(null);
                }}
              />
            </Form.Group>
            {/* <Form.Group>
              <Form.Label>Kunde</Form.Label>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  const select = JSON.parse(e.target.value) as Customer;
                  setMissingSelection(false);
                  setSelectedCustomer(select);
                  getProjects(select);
                  try {
                    getPlans(select, selectedProject!);
                  } catch (e) {
                    console.log(e);
                  }
                  setSelectedProject(null);
                }}
              >
                <option key={1} hidden>
                  Auswählen...
                </option>
                {customers?.map((customer: Customer) => (
                  <option key={customer.id} value={JSON.stringify(customer)}>
                    {customer.customerName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group> */}
            <Form.Group>
              <Form.Label>Projekt</Form.Label>
              <Select
                isDisabled={selectedCustomer ? false : true}
                placeholder="Auswählen..."
                options={projects ? projects : undefined}
                getOptionLabel={(option) => option.projectName}
                getOptionValue={(option) => JSON.stringify(option)}
                onChange={async (project) => {
                  setMissingSelection(false);
                  setSelectedProject(project);
                  getPlans(selectedCustomer!, project!);
                }}
              />
            </Form.Group>
            {/* <Form.Group>
              <Form.Label>Projekt</Form.Label>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  const select = JSON.parse(e.target.value) as Project;
                  setMissingSelection(false);
                  setSelectedProject(select);
                  getPlans(selectedCustomer!, select);
                }}
              >
                <option key={1} hidden>
                  Auswählen...
                </option>
                {projects?.map((project) => (
                  <option key={project.id} value={JSON.stringify(project)}>
                    {project.projectName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group> */}
            <Form.Group>
              <Form.Check
                type="checkbox"
                disabled={selectedCustomer === null || selectedProject === null}
                checked={!isNewPlan}
                label="Neuen Index hochladen"
                onChange={(e) => {
                  setIsNewPlan(!e.currentTarget.checked);
                }}
              />
            </Form.Group>
            {isNewPlan ? (
              <>
                <Form.Group>
                  <Form.Label>Plannummer</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Plannummer"
                    value={planId}
                    onChange={(e) => {
                      setPlanId(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Planbezeichnung</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Planbezeichnung"
                    value={planName}
                    onChange={(e) => setPlanName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Planbeschreibung</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    type="text"
                    placeholder="Planbeschreibung"
                    value={planDescription}
                    onChange={(e) => setPlanDescription(e.target.value)}
                  />
                </Form.Group>
              </>
            ) : (
              <>
                <Form.Group>
                  <Form.Label>Plan</Form.Label>
                  <Select
                    // isDisabled={selectedCustomer ? false : true}
                    placeholder="Auswählen..."
                    options={plans ? plans : undefined}
                    getOptionLabel={(option) => option.planName}
                    getOptionValue={(option) => JSON.stringify(option)}
                    onChange={async (plan) => {
                      setSelectedPlan(plan!);
                      checkIndices(plan!);
                    }}
                  />
                </Form.Group>
                {/* <Form.Group>
                  <Form.Label>Plan</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setSelectedPlan(JSON.parse(e.target.value) as Plan);
                      checkIndices(JSON.parse(e.target.value) as Plan);
                    }}
                  >
                    <option hidden>Auswählen...</option>
                    {plans?.map((plan) => (
                      <option key={plan.id} value={JSON.stringify(plan)}>
                        {plan.planName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group> */}
                <Form.Group>
                  <Form.Label>Index</Form.Label>
                  <Select
                    isDisabled={selectedPlan ? false : true}
                    placeholder="Auswählen..."
                    options={indices}
                    // getOptionLabel={(option) => option}
                    // getOptionValue={(option) => option}
                    onChange={async (index) => {
                      setMissingSelection(false);
                      setIndex(index!.label);
                    }}
                  />
                </Form.Group>
                {/* <Form.Group>
                  <Form.Label>Index</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    onChange={async (e) => {
                      setMissingSelection(false);
                      setIndex(e.target.value as string);
                    }}
                  >
                    <option hidden>Auswählen...</option>
                    {indices.map((index) => (
                      <option key={index} value={index}>
                        {index}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group> */}
                <Form.Group>
                  <Form.File
                    required
                    label="Dokument hochladen"
                    onChange={(e: any) => setFile(e.target.files[0])}
                  />
                </Form.Group>
              </>
            )}
            {missingSelection && (
              <Alert variant="danger">
                <p>
                  {isNewPlan
                    ? "Bitte wähle einen Kunden und ein Projekt aus."
                    : "Bitte wähle einen Kunden, ein Projekt und einen Index aus."}
                </p>
              </Alert>
            )}
            {missingPlan && (
              <Alert variant="danger">
                <p>Bitte wähle einen Plan aus.</p>
              </Alert>
            )}
            {uploading ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <div className="d-flex justify-content-end">
                <Button
                  variant="danger"
                  type="submit"
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                >
                  Anlegen
                </Button>
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setVisible(false)}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddPlanModal;
