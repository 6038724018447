import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FirebaseContext } from "../../../firebase/Firebase";

type ChangePasswordModalProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
};

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  visible,
  setVisible,
}) => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [validated, setValidated] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const firebase = useContext(FirebaseContext);

  const resetForm = () => {
    setNewPassword("");
    setValidated(false);
    setMessage("");
  };

  const onSubmit = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      event.stopPropagation();
      try {
        await firebase?.doPasswordUpdate(newPassword);
        setSuccess(true);
        setMessage("Passwort wurde erfolgreich aktualisiert.");
        setNewPassword("");
      } catch (error) {
        setSuccess(false);
        // @ts-ignore
        setMessage(error.message);
      }
    }
  };

  return (
    <>
      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => {
            onSubmit(e);
          }}
        >
          <Modal.Header closeButton onClick={resetForm}>
            <Modal.Title>Passwort ändern</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Control
                required
                type="password"
                placeholder="Neues Passwort eingeben..."
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {message !== "" ? (
                <div className="pt-3 px-1">
                  <p style={{ color: success ? "green" : "red" }}>{message}</p>
                </div>
              ) : null}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Form.Group className="d-flex justify-content-end">
              <Button variant="danger" type="submit">
                Ändern
              </Button>
            </Form.Group>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
