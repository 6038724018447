import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FirebaseContext } from "../../../firebase/Firebase";

type AddCustomerModalProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  refreshCustomers: () => void;
};

const AddCustomerModal: React.FC<AddCustomerModalProps> = ({
  visible,
  setVisible,
  refreshCustomers,
}) => {
  const [customerId, setCustomerId] = useState<string>("");
  const [customerName, setCustomerName] = useState<string>("");
  const firebase = useContext(FirebaseContext);

  const submit = async () => {
    try {
      await firebase!.addCustomer({
        id: "",
        customerId: customerId,
        customerName: customerName,
      });
      refreshCustomers();
      setVisible(false);
      setCustomerId("");
      setCustomerName("");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Kunde anlegen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGroupEmail">
              <Form.Label>Kundennummer</Form.Label>
              <Form.Control
                type="number"
                placeholder="Kundennummer eingeben..."
                value={customerId}
                onChange={(e) => setCustomerId(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formGroupPassword">
              <Form.Label>Bezeichnung</Form.Label>
              <Form.Control
                type="text"
                placeholder="Bezeichnung eingeben..."
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </Form.Group>
            <Button variant="danger" onClick={() => submit()}>
              Anlegen
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setVisible(false)}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddCustomerModal;
