import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { FirebaseContext } from "../../../firebase/Firebase";
import { Customer } from "../../../store/customers/types";
import { File } from "../../../store/files/types";
import { Plan } from "../../../store/plans/types";
import { Project } from "../../../store/projects/types";
import DeleteFileModal from "./EditPlanModal/DeleteFileModal";

type EditPlanModalProps = {
  customer: Customer;
  project: Project;
  plan: Plan;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  refresh: (customer: Customer, project: Project) => void;
};

type IndexOutputInformation = {
  file: File;
  output: string;
};

const EditPlanModal: React.FC<EditPlanModalProps> = ({
  customer,
  project,
  plan,
  visible,
  setVisible,
  refresh,
}) => {
  const firebase = useContext(FirebaseContext);
  const [uploading, setUploading] = useState<boolean>();
  // const [files, setFiles] = useState<File[]>([]);
  const [filesAsOutput, setFilesAsOutput] = useState<IndexOutputInformation[]>(
    []
  );

  const [currentFile, setCurrentFile] = useState<File>();
  const [tempPlan, setTempPlan] = useState<Plan>(plan);
  const [deleteFileModalVisible, setDeleteFileModalVisible] =
    useState<boolean>(false);

  const submit = async () => {
    try {
      //Aktualisieren des Plans in der Datenbank
      await firebase!.updatePlan(customer.id, project.id, tempPlan!);
      refresh(customer, project);
      setVisible(false);
      toast.success("Der Plan wurde erfolgreich aktualisiert.");
    } catch (error) {
      console.log(error);
      toast.error("Es ist ein Fehler aufgetreten. Bitte versuche es erneut.");
    }
  };

  useEffect(() => {
    //Holt die bereits existierenden Indizes und Files und zeigt diese an
    const getAndSetFiles = async () => {
      var files: File[] = await firebase!.getFiles(
        customer.id,
        project.id,
        plan.id
      );
      var tempArray: IndexOutputInformation[] = [];
      files.forEach(async (file) => {
        tempArray.push({
          file: file,
          output: "Index " + file.index,
          //+ " --- file: " + pdfOutput,
        });
      });
      setFilesAsOutput(tempArray);
      // setFiles(files);
    };
    setTempPlan(plan);

    getAndSetFiles();
  }, [visible, customer.id, firebase, plan, project.id]);

  //Validiert, ob der Index wirklich gelöscht werden soll
  const openDeleteFileModal = async (file: File) => {
    setCurrentFile(file);
    setVisible(false);
    setDeleteFileModalVisible(true);
  };

  //Löscht den Index und die pdf
  const deleteFile = async (file: File) => {
    setCurrentFile(file);
    try {
      await firebase!.deleteFile(customer.id, project.id, plan.id, file.file);
      await firebase!
        .getIndex(customer.id, project.id, plan.id, file.index)
        .then(async (tempIndex) => {
          await firebase!.deleteIndex(
            customer.id,
            project.id,
            plan.id,
            tempIndex.id
          );
        });
      toast.success("Index wurde erfolgreich gelöscht.");
      refresh(customer, project);
    } catch (error) {
      console.log(error);
      toast.error("Es ist ein Fehler aufgetreten. Bitte versuche es erneut.");
    }
  };

  return (
    <>
      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{plan.planName} bearbeiten</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={async (event) => {
              //Ruft die submit() function auf
              event.preventDefault();

              setUploading(true);
              await submit().then(() => {
                setUploading(false);
                setVisible(false);
              });
            }}
          >
            <Form.Group>
              <Form.Label>{"Kunde: " + customer.customerName}</Form.Label>
            </Form.Group>
            <Form.Group>
              <Form.Label>{"Projekt: " + project.projectName}</Form.Label>
            </Form.Group>
            <Form.Group>
              <Form.Label>Plannummer</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Plannummer"
                value={tempPlan!.planId}
                onChange={(e) => {
                  setTempPlan({
                    ...tempPlan,
                    planId: e.target.value,
                  } as Plan);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Planbezeichnung</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Planbezeichnung"
                value={tempPlan!.planName}
                onChange={(e) => {
                  setTempPlan({
                    ...tempPlan,
                    planName: e.target.value,
                  } as Plan);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Planbeschreibung</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                type="text"
                placeholder="Planbeschreibung"
                value={tempPlan!.planDescription}
                onChange={(e) =>
                  setTempPlan({
                    ...tempPlan,
                    planDescription: e.target.value,
                  } as Plan)
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Indizes:</Form.Label>
              {filesAsOutput.map((file) => (
                <div key={file.output} className="mt-2 border rounded p-2">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>{file.output}</div>
                    <Button
                      variant="danger"
                      onClick={(event) => {
                        openDeleteFileModal(file.file);
                        event.preventDefault();
                      }}
                    >
                      Löschen
                    </Button>
                  </div>
                </div>
              ))}
            </Form.Group>
            {uploading ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <div className="d-flex justify-content-end">
                <Button
                  variant="danger"
                  type="submit"
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                >
                  Aktualisieren
                </Button>
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setVisible(false)}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
      <DeleteFileModal
        file={currentFile!}
        visible={deleteFileModalVisible}
        setVisible={setDeleteFileModalVisible}
        deleteFile={(file: File) => deleteFile(file)}
        onCancel={() => {
          setDeleteFileModalVisible(false);
          setVisible(true);
        }}
      ></DeleteFileModal>
    </>
  );
};

export default EditPlanModal;
