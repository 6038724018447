import React from "react";
import Footer from "../../components/Footer";
import Header from "./components/Header";

type PrivacyPolicyProps = {};

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = () => {
  return (
    <>
      <Header link="/" title="Datenschutzerklärung" />
      <div className="container section-holder">
        <h2>1. Datenschutz auf einen Blick</h2>
        <h3>Allgemeine Hinweise</h3>
        <p>
          Die folgenden Hinweise geben einen einfachen Überblick darüber, was
          mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
          besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
          persönlich identifiziert werden können. Ausführliche Informationen zum
          Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
          Datenschutzerklärung.
        </p>
        <h3>Datenerfassung auf dieser Website</h3>
        <h4>
          Wer ist verantwortlich für die Datenerfassung auf dieser Website?
        </h4>
        <p>
          <strong>Powerconcept Monshausen GmbH</strong>
          <br />
          Stuttgarter Str. 74 <br />
          72555 Metzingen
        </p>
        <h4>Kontakt</h4>
        <p>
          <a href="mailto:info@power-concept.com">info@power-concept.com</a>{" "}
          <br />
          <a href="tel:+4971233741260">+49 (0) 7123 374 1260</a>
        </p>
        <h4>Wie erfassen wir Ihre Daten?</h4>
        <p>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie uns per
          Email senden.
          <br />
          Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
          Besuch der Website durch IT-Systeme des Hosters (Netlify) erfasst. Das
          sind vor allem technische Daten (z.B. IP-Adresse). Die Erfassung
          dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
        </p>
        <h4>Wofür nutzen wir Ihre Daten?</h4>
        <p>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
          Nutzerverhaltens verwendet werden.
        </p>
        <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
        <p>
          Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
          Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
          erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
          dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
          Datenverarbeitung erteilt haben, können Sie diese Einwilligung
          jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
          unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
          Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
        </p>
        <p>
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
          jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
        </p>
        <hr />
        <h2>2. Hosting und Content Delivery Networks (CDN)</h2>
        <h3>Externes Hosting</h3>
        <p>
          Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
          Die personenbezogenen Daten, die auf dieser Website erfasst werden,
          werden auf den Servern des Hosters gespeichert. Hierbei kann es sich
          v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
          Vertragsdaten, Kontaktdaten, Namen, Webseitenzugriffe und sonstige
          Daten, die über eine Website generiert werden, handeln.
        </p>
        <p>
          Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
          gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
          lit. b DSGVO) und im Interesse einer sicheren, schnellen und
          effizienten Bereitstellung unseres Online-Angebots durch einen
          professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
        </p>
        <p>
          Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
          Erfüllung seiner Leistungspflichten erforderlich ist und unsere
          Weisungen in Bezug auf diese Daten befolgen.
        </p>
        <p>
          Diese Website wird von Netlify, Inc., 2325 3rd Street, Suite 296, San
          Francisco, California 94107 gehosted.{" "}
          {/* Um die datenschutzkonforme
          Verarbeitung zu gewährleisten, haben wir einen Vertrag über
          Auftragsverarbeitung mit unserem Hoster geschlossen.  */}
          Für weitere Informationen hierzu verweisen wir auf{" "}
          <a
            className="color-gray"
            rel="noopener noreferrer"
            href="https://www.netlify.com/privacy/"
          >
            Netlify Privacy Policy
          </a>{" "}
          und{" "}
          <a
            className="color-gray"
            rel="noopener noreferrer"
            href="https://www.netlify.com/gdpr-ccpa"
          >
            Netlify’s commitment to protect your data
          </a>
          .
        </p>
        <hr />
        <h2>3. Allgemeine Hinweise und Pflichtinformationen</h2>
        <h3>Datenschutz</h3>
        <p>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
          sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
          entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
        </p>
        <p>
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
          persönlich identifiziert werden können. Die vorliegende
          Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
          sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
          geschieht.
        </p>
        <p>
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
          der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
          lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
          möglich.
        </p>
        <h3>Hinweis zur verantwortlichen Stelle</h3>
        <p>
          <strong>Powerconcept Monshausen GmbH</strong>
          <br />
          Stuttgarter Str. 74 <br />
          72555 Metzingen
        </p>
        <h4>Kontakt</h4>
        <p>
          <a href="mailto:info@power-concept.com">info@power-concept.com</a>{" "}
          <br />
          <a href="tel:+4971233741260">+49 (0) 7123 374 1260</a>
        </p>
        <p>
          Verantwortliche Stelle ist die natürliche oder juristische Person, die
          allein oder gemeinsam mit anderen über die Zwecke und Mittel der
          Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen
          o. Ä.) entscheidet.
        </p>
        <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
        <p>
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
          Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
          jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail
          an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
          Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <h3>
          Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
          gegen Direktwerbung (Art. 21 DSGVO)
        </h3>
        <p>
          Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 Lit. E oder
          F DSGVO erfolgt, haben sie jederzeit das Recht, aus Gründen, die sich
          aus ihrer besonderen Situation ergeben, gegen die Verarbeitung ihrer
          personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für ein
          auf diese Bestimmungen gestütztes Profiling. Die jeweilige
          Rechtsgrundlage, auf denen eine Verarbeitung beruht, entnehmen sie
          dieser Datenschutzerklärung. Wenn sie Widerspruch einlegen, werden wir
          ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es
          sei denn, wir können zwingende schutzwürdige Gründe für die
          Verarbeitung nachweisen, die ihre Interessen, Rechte und Freiheiten
          überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung
          oder Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs.
          1 DSGVO).
        </p>
        <p>
          Werden ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu
          betreiben, so haben sie das Recht, jederzeit Widerspruch gegen die
          Verarbeitung sie betreffender personenbezogener Daten zum Zwecke
          derartiger Werbung einzulegen; dies gilt auch für das Profiling,
          soweit es mit solcher Direktwerbung in Verbindung steht. Wenn sie
          widersprechen, werden ihre personenbezogenen Daten anschließend nicht
          mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21
          Abs. 2 DSGVO).
        </p>
        <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
        <p>
          Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
          Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
          Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
          oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
          besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
          gerichtlicher Rechtsbehelfe.
        </p>
        <h3>Recht auf Datenübertragbarkeit</h3>
        <p>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
          oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
          einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
          technisch machbar ist.
        </p>
        <h3>SSL- bzw. TLS-Verschlüsselung</h3>
        <p>
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
          Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
          TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
          daran, dass die Adresszeile des Browsers von http:// auf https://
          wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
        </p>
        <p>
          Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
          Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
          werden.
        </p>
        <h3>Auskunft, Löschung und Berichtigung</h3>
        <p>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck
          der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
          Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
          personenbezogene Daten können Sie sich jederzeit unter der im
          Impressum angegebenen Adresse an uns wenden.
        </p>
        <h3>Recht auf Einschränkung der Verarbeitung</h3>
        <p>
          Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
          unter der im Impressum angegebenen Adresse an uns wenden. Das Recht
          auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
        </p>
        <p>
          <ul>
            <li>
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
              personenbezogenen Daten bestreiten, benötigen wir in der Regel
              Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
              das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li>
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
              geschah/geschieht, können Sie statt der Löschung die Einschränkung
              der Datenverarbeitung verlangen.
            </li>
            <li>
              Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
              sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
              Rechtsansprüchen benötigen, haben Sie das Recht, statt der
              Löschung die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li>
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
              haben, muss eine Abwägung zwischen Ihren und unseren Interessen
              vorgenommen werden. Solange noch nicht feststeht, wessen
              Interessen überwiegen, haben Sie das Recht, die Einschränkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
          </ul>
        </p>
        <p>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
          haben, dürfen diese Daten - von ihrer Speicherung abgesehen - nur mit
          Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
          von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
          natürlichen oder juristischen Person oder aus Gründen eines wichtigen
          öffentlichen Interesses der Europäischen Union oder eines
          Mitgliedstaats verarbeitet werden.
        </p>
        <hr />
        <h2>4. Datenerfassung auf dieser Website</h2>
        <h3>Server-Log-Dateien</h3>
        <p>
          Der Provider der Seiten erhebt und speichert automatisch Informationen
          in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
          übermittelt. Diese können beinhalten:
        </p>
        <p>
          <ul>
            <li>Browsertyp und Browserversion</li>
            <li>verwendetes Betriebssystem</li>
            <li>Referrer URL</li>
            <li>Hostname des zugreifenden Rechners</li>
            <li>Uhrzeit der Serveranfrage</li>
            <li>IP-Adresse</li>
          </ul>
        </p>
        <p>
          Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
          der technisch fehlerfreien Darstellung und der Optimierung seiner
          Website - hierzu müssen die Server-Log-Files erfasst werden.
        </p>
        <hr />
        <h2>5. Speicherung der Daten</h2>
        <p>
          Unsere Website/App nutzt Google Firebase (Google LLC,1600 Amphitheatre
          Parkway, Mountain View, CA 94043, USA, “Google”). Dieser Dienst
          verarbeitet u.a. auch personenbezogene Daten. Meist handelt es sich
          hierbei um „Instance IDs“, die mit einem Zeitstempel versehen sind.
          Diese IDs werde einem bestimmten Nutzer zugeordnet und erlauben die
          Verknüpfung von unterschiedlichen Geschehnissen oder Abläufen. Diese
          Daten geben für uns keinen Rückschluss auf den konkreten Nutzer. Eine
          Personalisierung erfolgt durch uns nicht. Wir verarbeiten diese
          zusammengefassten Daten zur Analyse und Optimierung des
          Nutzungsverhaltens, wie beispielsweise durch die Auswertung von
          Absturzberichten.
        </p>
        <p>
          Die Datenschutzerklärung können Sie hier einsehen: <br />
          <a href="https://firebase.google.com/support/privacy/">
            https://firebase.google.com/support/privacy/
          </a>
        </p>

        <p>
          Wir nutzen nach Möglichkeit Server mit einem Standort innerhalb der
          EU. Es ist aber nicht auszuschließen, dass Daten auch in die USA
          übertragen werden. Google ist dem EU-US Privacy-Shield beigetreten.
        </p>

        <hr />
        <h2>6. Adobe Fonts (Typekit)</h2>
        <p>
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
          genannte Web Fonts, die von Adobe Typekit bereitgestellt werden. Beim
          Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
          Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Zu diesem
          Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern
          von Adobe Typekit aufnehmen. Hierdurch erlangt Adobe Typekit Kenntnis
          darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde.
          Die Nutzung von Adobe Typekit Web Fonts erfolgt im Interesse einer
          einheitlichen und ansprechenden Darstellung unserer Online-Angebote.
          Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit.
          f DSGVO dar. Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
          Standardschrift von Ihrem Computer genutzt. Weitere Informationen zu
          Adobe Typekit Web Fonts finden Sie unter{" "}
          <a
            className="color-gray"
            rel="noopener noreferrer"
            href="https://typekit.com/"
          >
            Typekit
          </a>{" "}
          und in der{" "}
          <a
            className="color-gray"
            rel="noopener noreferrer"
            href="https://www.adobe.com/de/privacy/policies/typekit.html"
          >
            Datenschutzerklärung
          </a>{" "}
          von Adobe Typekit.
        </p>
        <hr />
        <h2>7. Cookies</h2>

        <table className="table">
          <thead>
            <tr>
              <th scope="col">Anbieter</th>
              <th scope="col">Name</th>
              <th scope="col">Laufzeit</th>
              <th scope="col">Zweck</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Google</td>
              <td>_ga</td>
              <td>2 Jahre</td>
              <td>Unterscheidung von Nutzern</td>
            </tr>
            <tr>
              <td>Google</td>
              <td>_gid</td>
              <td>24 Stunden</td>
              <td>Unterscheidung von Nutzern</td>
            </tr>
          </tbody>
        </table>

        {/* <h3>7.1 Google Analytics for Firebase</h3>
        <p>
          Die gesammelten Daten helfen uns, die Nutzung der App besser zu
          verstehen und das Angebot zu verbessern. Alle IP-Adressen werden nur
          in anonymisierter Form gespeichert. Daher können die dabei gewonnenen
          Daten nicht dazu verwendet werden, Nutzende persönlich zu
          identifizieren. Firebase Analytics nutzt eine Werbe-ID. In den
          Geräteeinstellungen Ihres Mobilgerätes können Sie diese Nutzung
          beschränken.
        </p>
        <p>
          Für Android: Einstellungen {">"} Google {">"} Anzeigen {">"} Werbe-ID
          zurücksetzen
        </p>
        <p>
          Für iOS: Einstellungen {">"} Datenschutz {">"} Werbung {">"} Kein
          Ad-Tracking
        </p>
        <p>
          Weitere Informationen zu Google Firebase und Datenschutz finden hier:{" "}
          <br />
          <a href="https://www.google.com/policies/privacy/">
            {" "}
            https://www.google.com/policies/privacy/
          </a>
        </p> */}
        <hr />
        <p>
          Zu Teilen aus:{" "}
          <a
            className="color-gray"
            rel="noopener noreferrer"
            href="https://www.e-recht24.de"
          >
            https://www.e-recht24.de
          </a>
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
