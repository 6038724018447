import { CREATE_CUSTOMERS, CustomerActionTypes, Customers } from "./types";

const initialState: Customers = [];

export function customerReducer(
  state = initialState,
  action: CustomerActionTypes
): Customers {
  switch (action.type) {
    case CREATE_CUSTOMERS:
      return (state = action.payload);
    default:
      return state;
  }
}
