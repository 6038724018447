import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { FirebaseContext } from "../../../firebase/Firebase";
import { Customer, Customers } from "../../../store/customers/types";
import { Project } from "../../../store/projects/types";

type AddProjectModalProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  customers: Customers;
  selectedCustomer: Customer | null;
  setSelectedCustomer: Dispatch<SetStateAction<Customer | null>>;
  getProjects: (customer: Customer) => void;
  setSelectedProject: Dispatch<SetStateAction<Project | null>>;
};

const AddProjectModal: React.FC<AddProjectModalProps> = ({
  visible,
  setVisible,
  customers,
  selectedCustomer,
  setSelectedCustomer,
  getProjects,
  setSelectedProject,
}) => {
  const firebase = useContext(FirebaseContext);

  const [projectId, setProjectId] = useState<string>();
  const [projectName, setProjectName] = useState<string>();

  const submit = async () => {
    try {
      await firebase!.addProject(selectedCustomer!.id, {
        id: "",
        projectId: projectId!,
        projectName: projectName!,
      });
      // TODO: add refresh projects
      setVisible(false);
      setProjectId("");
      setProjectName("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Projekt anlegen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Kunde</Form.Label>
              <Select
                placeholder="Auswählen..."
                options={customers}
                getOptionLabel={(option) => option.customerName}
                getOptionValue={(option) => JSON.stringify(option)}
                onChange={async (project) => {
                  setSelectedCustomer(project!);
                  getProjects(project!);
                  setSelectedProject(null);
                }}
              />
            </Form.Group>
            {/* <Form.Group>
              <Form.Label>Kunde</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  const select = JSON.parse(e.target.value) as Customer;
                  setSelectedCustomer(select);
                  getProjects(select);
                  setSelectedProject(null);
                }}
              >
                <option key={1}>Auswählen...</option>
                {customers?.map((customer: Customer) => (
                  <option key={customer.id} value={JSON.stringify(customer)}>
                    {customer.customerName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group> */}
            <Form.Group>
              <Form.Label>Projektnummer</Form.Label>
              <Form.Control
                type="text"
                placeholder="Projektnummer"
                value={projectId}
                onChange={(e) => setProjectId(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Projektname</Form.Label>
              <Form.Control
                type="text"
                placeholder="Projektname"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
              />
            </Form.Group>
            <Button variant="danger" onClick={() => submit()}>
              Anlegen
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setVisible(false)}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddProjectModal;
