import React from "react";
import Footer from "../../components/Footer";
import Header from "./components/Header";

type ImprintProps = {};

const Imprint: React.FC<ImprintProps> = () => {
  return (
    <>
      <Header link="/" title="Impressum" />
      <div className="container section-holder">
        <h3 className="">Adresse</h3>
        <p className="">
          <strong>Powerconcept Monshausen GmbH</strong>
          <br />
          Stuttgarter Str. 74 <br />
          72555 Metzingen
        </p>
        <div className="pt-4"></div>
        <h3 className="">Kontakt</h3>
        <p className="">
          <span style={{ fontWeight: "bold" }}>Telefon</span>:{" "}
          <a href="tel:+4971233741260">+49 (0) 7123 374 1260</a>
        </p>
        <p className="">
          <span style={{ fontWeight: "bold" }}>E-Mail</span>:{" "}
          <a href="mailto:info@power-concept.com">info@power-concept.com</a>
        </p>
        <div className="pt-4"></div>
        <h3 className="">Geschäftsführer</h3>
        <p className="">Matthias Monshausen</p>
        <div className="pt-4"></div>
        <h3 className="">Registergericht</h3>
        <p className="">Amtsgericht Stuttgart</p>
        <div className="pt-4"></div>
        <h3 className="">Handelsregister</h3>
        <p className="">HRB 775178</p>
        <div className="pt-4"></div>
        <h3 className="">Technische Umsetzung</h3>
        <p className="">
          Kevin Ludwig Web- und Softwareentwicklung
          <br />
          <strong>Kontakt:</strong>{" "}
          <a href="mailto:mail@kevinludwig.com">mail@kevinludwig.com</a>
        </p>
        <div className="pt-4"></div>
        <h3 className="">Haftungshinweis</h3>
        <p className="">
          Trotz sorgfältiger inhaltlicher Kontrolle wird keine Haftung für die
          Inhalte externer Links übernommen. Für den Inhalt der verlinkten
          Seiten sind ausschließlich deren Betreiber verantwortlich.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Imprint;
