import React, { Dispatch, SetStateAction } from "react";
import { Breadcrumb, Spinner } from "react-bootstrap";
import { Customer, Customers } from "../store/customers/types";
import { Plans } from "../store/plans/types";
import { Project, Projects } from "../store/projects/types";
import CustomerCard from "./overview/CustomerCard";
import PlanCard from "./overview/PlanCard";
import ProjectCard from "./overview/ProjectCard";

type DocumentOverviewProps = {
  isLoading: boolean;
  customers: Customers;
  projects: Projects | null;
  getProjects: (customer: Customer) => void;
  selectedProject: Project | null;
  setSelectedProject: Dispatch<SetStateAction<Project | null>>;
  plans: Plans | null;
  getPlans: (customer: Customer, project: Project) => void;
  selectedCustomer: Customer | null;
  setSelectedCustomer: Dispatch<SetStateAction<Customer | null>>;
  getCustomers: () => void;
};

const DocumentOverview: React.FC<DocumentOverviewProps> = ({
  isLoading,
  customers,
  getCustomers: refreshCustomers,
  projects,
  getProjects,
  plans,
  getPlans,
  selectedCustomer,
  setSelectedCustomer,
  selectedProject,
  setSelectedProject,
}) => {
  /**
   * TODO:
   * - add localStorage
   * - only refresh when:
   *    - older than one hour
   *    - refresh button pressed
   *    - localStorage empty
   */

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="text-dark">Übersicht</h2>
        <Breadcrumb>
          {selectedCustomer ? (
            <>
              <Breadcrumb.Item
                className=""
                onClick={() => {
                  setSelectedCustomer(null);
                  setSelectedProject(null);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => setSelectedProject(null)}>
                {selectedCustomer?.customerName}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{selectedProject?.projectName}</Breadcrumb.Item>
            </>
          ) : (
            <>
              <Breadcrumb.Item>{"/"}</Breadcrumb.Item>
            </>
          )}
        </Breadcrumb>
      </div>
      <div>
        {isLoading ? (
          <div className="d-flex h-100 align-items-center justify-content-center">
            <Spinner animation="grow" variant="danger" />
          </div>
        ) : (
          <>
            {selectedCustomer ? (
              selectedProject ? (
                <>
                  {plans!.map((plan) => (
                    <div key={plan.id}>
                      <PlanCard
                        plan={plan}
                        selectedCustomer={selectedCustomer}
                        selectedProject={selectedProject}
                        getPlans={getPlans}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {projects!.map((project) => (
                    <div key={project.id}>
                      <ProjectCard
                        project={project}
                        selectedCustomer={selectedCustomer}
                        setSelectedProject={setSelectedProject}
                        getProjects={getProjects}
                        getPlans={getPlans}
                      />
                    </div>
                  ))}
                </>
              )
            ) : (
              <>
                {customers!.map((customer) => (
                  <div key={customer.id}>
                    <CustomerCard
                      setSelectedCustomer={setSelectedCustomer}
                      getProjects={getProjects}
                      getCustomers={refreshCustomers}
                      customer={customer}
                    />
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DocumentOverview;
