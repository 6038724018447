import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FirebaseContext } from "../firebase/Firebase";
import { Query } from "../screens/PlanScreen";
import { ReqFile } from "../store/plans/types";

type DropdownItemProps = { file: ReqFile; query: Query };

const DropdownItem: React.FC<DropdownItemProps> = ({ file, query }) => {
  const [url, setUrl] = useState<string>();
  const firebase = useContext(FirebaseContext);

  useEffect(() => {
    console.log("test");
    const getPlanUrl = async () => {
      const url = await firebase!.getDownloadUrl(
        `plans/${query!.customer}/${query!.project}/${query!.plan}/${file.file}`
      );
      setUrl(url);
    };

    getPlanUrl();
  }, [file, firebase, query]);

  return (
    <>
      <Dropdown.Item
        key={file.index}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="d-flex align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-file-earmark-ruled"
            viewBox="0 0 16 16"
          >
            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h7v1a1 1 0 0 1-1 1H6zm7-3H6v-2h7v2z" />
          </svg>
          <div className="px-1"></div>
          Index {file.index}
        </div>
      </Dropdown.Item>
    </>
  );
};

export default DropdownItem;
