import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxStoreProvider } from "react-redux";
import App from "./App";
import Firebase, { FirebaseContext } from "./firebase/Firebase";
import store from "./store";
import reportWebVitals from "./reportWebVitals";
import "./styles/main.scss";

ReactDOM.render(
  <>
    <FirebaseContext.Provider value={new Firebase()}>
      <ReduxStoreProvider store={store}>
        <App />
      </ReduxStoreProvider>
    </FirebaseContext.Provider>
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
