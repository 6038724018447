import React, { Dispatch, SetStateAction, useState } from "react";
import { Customer } from "../../store/customers/types";
import { Project } from "../../store/projects/types";
import DeleteEntityModal from "../modals/DeleteEntityModal";
import EditProjectModal from "../modals/project/EditProjectModal";
import InformationCard from "./InformationCard";

type ProjectCardProps = {
  project: Project;
  setSelectedProject: Dispatch<SetStateAction<Project | null>>;
  selectedCustomer: Customer;
  getProjects: (customer: Customer) => void;
  getPlans: (customer: Customer, project: Project) => void;
};

const ProjectCard: React.FC<ProjectCardProps> = ({
  project,
  setSelectedProject,
  selectedCustomer,
  getProjects,
  getPlans,
}) => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  return (
    <>
      <EditProjectModal
        customer={selectedCustomer}
        project={project}
        visible={editModalVisible}
        setVisible={setEditModalVisible}
        refresh={getProjects}
      />
      <DeleteEntityModal
        entity="project"
        customer={selectedCustomer}
        project={project}
        refresh={() => {
          getProjects(selectedCustomer);
        }}
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
      />
      <InformationCard
        cardOnClick={() => {
          setSelectedProject(project);
          getPlans(selectedCustomer, project);
        }}
        editOnClick={() => setEditModalVisible(true)}
        deleteOnClick={() => {
          setDeleteModalVisible(true);
        }}
        id={project.projectId}
        name={project.projectName}
      />
    </>
  );
};

export default ProjectCard;
