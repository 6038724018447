import { CREATE_USER_DATA, UserDataActionTypes, UserData } from "./types";

const initialState: UserData = {
  name: "",
  role: "",
};

export function userDataReducer(
  state = initialState,
  action: UserDataActionTypes
): UserData {
  switch (action.type) {
    case CREATE_USER_DATA:
      return (state = action.payload);
    default:
      return state;
  }
}
