import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Modal } from "react-bootstrap";
import { FirebaseContext } from "../../../firebase/Firebase";

type UserManagementModalProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
};

type User = {
  uid: string;
  name: string;
  role: string;
};

type Users = User[];

const UserManagementModal: React.FC<UserManagementModalProps> = ({
  visible,
  setVisible,
}) => {
  const [users, setUsers] = useState<Users>([]);
  const firebase = useContext(FirebaseContext);

  useEffect(() => {
    const getUsers = async () => {
      const userArray: Users = [];
      const users = await firebase!.getUsers();
      users!.forEach((user) => {
        const data = user.data();
        userArray.push({ uid: user.id, name: data.name, role: data.role });
      });
      setUsers(userArray);
    };
    getUsers();
  }, [firebase]);

  return (
    <Modal
      show={visible}
      onHide={() => setVisible(false)}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Benutzerverwaltung</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {users.map((user: User) => (
          <div key={user.uid}>
            <p>{user.name}</p>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};

export default UserManagementModal;
