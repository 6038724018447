import React from "react";
import { Link } from "react-router-dom";

type FooterProps = {};

const Footer: React.FC<FooterProps> = () => {
  return (
    <>
      <footer className="section-holder-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 d-flex align-items-center footer-col-center">
              <img style={{ width: 240 }} src="/logo-white.png" alt="logo" />
              {/* <Img
              className="logo"
              style={{ width: 240 }}
              fluid={data.logo.childImageSharp.fluid}
            /> */}
              <div>
                {/* <hr />
              <p className="text-white">
                {/* <strong>Powerconcept Monshausen GmbH</strong>
                <br />
                Stuttgarter Str. 74
                <br />
                72555 Metzingen
                <br />
                Phone: +49 (0) 7123 374 1260
                <br />
                Fax: +49 (0) 7123 374 1269
                <br />
                Mobil: +49 (0) 152 02367619
                <br />
                E-Mail: info@power-concept.com
              </p> */}
              </div>
            </div>
            <div className="col-lg-4"></div>
            <div className="col-lg-4 d-flex footer-legal-col align-items-center footer-col-center">
              <div className="text-white footer-legal">
                <h5>Legal</h5>
                <Link to="/imprint" className="text-white">
                  Impressum
                </Link>
                <br />
                <Link to="/privacy-policy" className="text-white">
                  Datenschutz
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
