import React from "react";
import { Link } from "react-router-dom";

type HeaderProps = {
  link: string;
  title: string;
};

const Header: React.FC<HeaderProps> = ({ link, title }) => {
  return (
    <>
      <div className="bg-light">
        <div className="container py-3 d-flex">
          <div className="my-auto">
            <Link to={link} className="mr-4 text-dark">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-chevron-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </Link>
          </div>
          <h2 className="my-auto text-dark d-flex align-items-center">
            {title}
          </h2>
        </div>
      </div>
    </>
  );
};

export default Header;
