import React, { Dispatch, SetStateAction } from "react";
import { Button, Modal } from "react-bootstrap";
import { File } from "../../../../store/files/types";

type DeleteFileModalProps = {
  file: File;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  deleteFile: (file: File) => void;
  onCancel: () => void;
};

const DeleteFileModal: React.FC<DeleteFileModalProps> = ({
  file,
  visible,
  setVisible,
  deleteFile,
  onCancel,
}) => {
  return (
    <>
      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Index ${file?.index} wirklich löschen?`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Dieser Schritt kann nicht rückgängig gemacht werden.
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ width: 100 }} variant="light" onClick={onCancel}>
            Abbrechen
          </Button>

          <Button
            style={{ width: 100 }}
            variant="danger"
            onClick={() => {
              deleteFile(file);
              setVisible(false);
            }}
          >
            Löschen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteFileModal;
