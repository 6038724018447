import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { FirebaseContext } from "../../../firebase/Firebase";
import { Customer } from "../../../store/customers/types";

type EditCustomerModalProps = {
  customer: Customer;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  refresh: () => void;
};

const EditCustomerModal: React.FC<EditCustomerModalProps> = ({
  customer,
  visible,
  setVisible,
  refresh,
}) => {
  const [uploading, setUploading] = useState<boolean>();
  const [tempCustomer, setTempCustomer] = useState<Customer>(customer);
  const firebase = useContext(FirebaseContext);

  const submit = async () => {
    try {
      //Aktualisieren des Plans in der Datenbank
      await firebase!.updateCustomer(tempCustomer);
      refresh();
      setVisible(false);
      toast.success("Der Kunde wurde erfolgreich aktualisiert.");
    } catch (error) {
      console.log(error);
      toast.error("Es ist ein Fehler aufgetreten. Bitte versuche es erneut.");
    }
  };

  useEffect(() => {
    setTempCustomer(customer);
  }, [visible, customer]);

  return (
    <>
      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{customer.customerName} bearbeiten</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={async (event) => {
              //Ruft die submit() function auf
              event.preventDefault();

              setUploading(true);
              await submit().then(() => {
                setUploading(false);
                setVisible(false);
              });
            }}
          >
            <Form.Group controlId="formGroupEmail">
              <Form.Label>Kundennummer</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Kundennummer eingeben..."
                value={tempCustomer.customerId}
                onChange={(e) =>
                  setTempCustomer({
                    ...tempCustomer,
                    customerId: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="formGroupPassword">
              <Form.Label>Bezeichnung</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Bezeichnung eingeben..."
                value={tempCustomer.customerName}
                onChange={(e) =>
                  setTempCustomer({
                    ...tempCustomer,
                    customerName: e.target.value,
                  })
                }
              />
            </Form.Group>
            {uploading ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <div className="d-flex justify-content-end">
                <Button
                  variant="danger"
                  type="submit"
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                >
                  Aktualisieren
                </Button>
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setVisible(false)}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditCustomerModal;
