import React, { Dispatch, SetStateAction, useState } from "react";
import { Customer } from "../../store/customers/types";
import DeleteEntityModal from "../modals/DeleteEntityModal";
import EditCustomerModal from "../modals/customer/EditCustomerModal";
import InformationCard from "./InformationCard";

type CustomerCardProps = {
  customer: Customer;
  getCustomers: () => void;
  getProjects: (customer: Customer) => void;
  setSelectedCustomer: Dispatch<SetStateAction<Customer | null>>;
};

const CustomerCard: React.FC<CustomerCardProps> = ({
  customer,
  getCustomers,
  getProjects,
  setSelectedCustomer,
}) => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  return (
    <>
      <EditCustomerModal
        customer={customer}
        visible={editModalVisible}
        setVisible={setEditModalVisible}
        refresh={getCustomers}
      />
      <DeleteEntityModal
        entity="customer"
        customer={customer}
        refresh={getCustomers}
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
      />
      <InformationCard
        cardOnClick={() => {
          setSelectedCustomer(customer);
          getProjects(customer);
        }}
        editOnClick={() => setEditModalVisible(true)}
        deleteOnClick={() => setDeleteModalVisible(true)}
        id={customer.customerId}
        name={customer.customerName}
      />
    </>
  );
};

export default CustomerCard;
