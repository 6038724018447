import React, { useContext, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FirebaseContext } from "../firebase/Firebase";

type LoginFormProps = {};

const LoginForm: React.FC<LoginFormProps> = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const firebase = useContext(FirebaseContext);
  const navigate = useNavigate();

  const onSubmit = async (event: any) => {
    setIsLoading(true);
    event.preventDefault();
    event.stopPropagation();
    try {
      await firebase!.setPersistenceLocal().then(() => {
        return firebase!.doSignInWithEmailAndPassword(email, password);
      });
      setIsLoading(false);
      navigate("/home");
    } catch (error) {
      // @ts-ignore
      switch (error.code) {
        case "auth/invalid-email":
          setMessage("Die angegebene E-Mail ist ungültig.");
          break;
        case "auth/user-not-found":
          setMessage(
            "Zur eingegebenen E-Mail-Adresse wurde kein Benutzer gefunden."
          );
          break;
        case "auth/wrong-password":
          setMessage("Das eingegebene Passwort ist falsch.");
          break;
        case "auth/user-disabled":
          setMessage("Dieser Benutzer wurde gesperrt.");
          break;
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <Form className="py-4 login-wrapper" onSubmit={onSubmit} noValidate>
        <Form.Group>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            aria-label="Password:"
            aria-describedby="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Form.Text className="text-muted">{message}</Form.Text>
        </Form.Group>
        <Form.Group>
          {isLoading ? (
            <Spinner className="mb-3" animation="grow" variant="danger" />
          ) : (
            <Button variant="danger" type="submit">
              Login
            </Button>
          )}
        </Form.Group>
      </Form>
    </>
  );
};

export default LoginForm;
