export interface UserData {
  name: string;
  role: string;
}

export const CREATE_USER_DATA = "CREATE_USER_DATA";
export const DELETE_USER_DATA = "DELETE_USER_DATA";

interface CreateUserDataAction {
  type: typeof CREATE_USER_DATA;
  payload: UserData;
}

interface DeleteUserDataAction {
  type: typeof DELETE_USER_DATA;
}

export type UserDataActionTypes =
  | CreateUserDataAction
  | DeleteUserDataAction;
