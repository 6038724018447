import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Plan } from "../../store/plans/types";
import QrViewer from "../QrViewer";

type QrModalProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  customerId?: string;
  projectId?: string;
  plan: Plan;
};

const QrModal: React.FC<QrModalProps> = ({
  visible,
  setVisible,
  customerId,
  projectId,
  plan,
}) => {
  const [url, setUrl] = useState<string>(
    `http://app.power-concept.com/#/plan/requestPlan?customer=${customerId}&project=${projectId}&plan=${plan.id}`
  );

  return (
    <>
      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>QR Zugriff</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <h3>{plan.planName}</h3>
          <p>{plan.planDescription}</p>
          <div className="d-flex flex-column align-items-center">
            <QrViewer plan={plan} url={url} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              setVisible(false);
              setUrl("");
            }}
          >
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QrModal;
