import React from "react";
import NavigationBar from "./NavigationBar";

type LayoutProps = {};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <NavigationBar title={"Powerconcept App"} />
      <div className="wrapper">
        <main>{children}</main>
      </div>
    </>
  );
};

export default Layout;
