import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { FirebaseContext } from "./firebase/Firebase";
import HomeScreen from "./screens/HomeScreen";
import Imprint from "./screens/Legal/Imprint";
import PrivacyPolicy from "./screens/Legal/PrivacyPolicy";
import LoginScreen from "./screens/LoginScreen";
import { PlanScreen } from "./screens/PlanScreen";
import { createUserData } from "./store/user/actions";

type UserData = {
  name: string;
  role: string;
};

function App() {
  const [initializing, setIsInitializing] = useState(true);
  const firebase = useContext(FirebaseContext);
  const [isAuth, setIsAuth] = useState<boolean>(!!firebase!.auth.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserData = async (uid: string) => {
      const data = await firebase!.getUserById(uid);
      setIsInitializing(false);
      dispatch(createUserData(data.data() as UserData));
    };

    const unsubscribe = firebase!.auth.onAuthStateChanged((user) => {
      setIsAuth(!!user);
      if (user) {
        getUserData(user!.uid);
      } else {
        setIsInitializing(false);
      }
    });
    return unsubscribe;
  }, [dispatch, firebase]);

  if (initializing) {
    return (
      <div className="app-loader d-flex flex-column align-items-center justify-content-center">
        <Spinner className="mb-3" animation="grow" variant="danger" />
      </div>
    );
  }

  return (
    <>
      <div>
        <Toaster />
      </div>
      <Router>
        <Routes>
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/plan/:data" element={<PlanScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route
            path="/home"
            element={isAuth ? <HomeScreen /> : <Navigate to="/login" />}
          />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
