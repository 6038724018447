import React from "react";
import { Link } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import logo from "../logo.png";

type LoginScreenProps = {};

const LoginScreen: React.FC<LoginScreenProps> = () => {
  return (
    <>
      <div className="wrapper">
        <div className="d-flex flex-column justify-content-center align-items-center login-form-wrapper">
          <img
            className="login-screen-logo"
            src={logo}
            alt="Powerconcept Monshausen"
          />
          <LoginForm />
          <div className="mb-4">
            &copy; 2021 Powerconcept Monshausen GmbH. All rights reserved.
          </div>
          <div className="d-flex">
            <Link to="/imprint">Impressum</Link>
            <Link className="ml-3" to="/privacy-policy">
              Datenschutzerklärung
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
